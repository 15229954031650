<template>
  <VDatePicker
    ref="datePicker"
    :masks="{ input: 'DD-MM-YYYY' }"
    v-bind="$attrs"
    v-model="date"
    class="DatePicker"
  >
    <template #header-title="{ yearLabel, monthLabel }">
      <div class="DatePicker__innerHeader">
        <span>{{ monthLabel + ' ' + yearLabel }}</span>
        <i class="DatePicker__dropdownIcon fas fa-caret-down" />
      </div>
    </template>
  </VDatePicker>
</template>

<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'DatePicker',

  components: {
    VDatePicker,
  },

  props: {
    value: {
      type: Date,
      required: true,
    },
  },

  computed: {
    date: {
      get() {
        return this.value;
      },
      set(date) {
        this.$emit('input', date !== null ? date : this.value);
      },
    },
  },

  watch: {
    date(to) {
      this.$refs.datePicker.move(to);
    },
  },
};
</script>

<style lang="scss" scoped>
.DatePicker {
  .DatePicker__innerHeader {
    padding: 2px 20px;
    border-radius: 4px;
    &:hover {
      background-color: #edf2f7;
    }
  }

  .DatePicker__dropdownIcon {
    font-size: 14px;
    margin-left: 5px;
  }
}
</style>

<template>
  <Popover
    v-if="!showAsModal"
    toggleOnClick
    :disabled="disabled"
    class="DateRangePicker"
    @open="resetDates"
  >
    <template #default>
      <DateRangeButton :startDate="startDate" :endDate="endDate" :disabled="disabled" />
    </template>
    <template #content="{ close }">
      <div class="DateRangePicker__popoverContent">
        <div class="DateRangePicker__datePickerContainer">
          <DatePicker
            v-model="inputStart"
            :attributes="startAttributes"
            class="DateRangePicker__datePicker"
            @input="resetOptions"
          />
          <DatePicker
            v-model="inputEnd"
            :attributes="endAttributes"
            class="DateRangePicker__datePicker"
            @input="resetOptions"
          />
        </div>
        <div class="DateRangePicker__controls">
          <div class="DateRangePicker__optionsContainer">
            <DateRangeOptions
              ref="options"
              :alignment="Alignments.LEFT"
              class="DateRangePicker__options"
              @change="setRange"
            />
          </div>
          <div class="DateRangePicker__footer">
            <Button :color="colors.WHITE" :size="ButtonSizes.MEDIUM2" @click="close">
              {{ $t('cancel') }}
            </Button>
            <Button :size="ButtonSizes.MEDIUM2" @click="changeDate(), close()">
              {{ $t('choose') }}
            </Button>
          </div>
        </div>
      </div>
    </template>
  </Popover>

  <div v-else :class="['DateRangePicker', 'DateRangePicker--modal']">
    <DateRangeButton
      :startDate="startDate"
      :endDate="endDate"
      :disabled="disabled"
      @click="openModal"
    />
    <ModalDialog
      ref="modal"
      :title="$t('date_picker.dialog.title')"
      :size="ModalSizes.AUTO"
      :confirmLabel="$t('choose')"
      @confirm="changeDate(), closeModal()"
    >
      <div class="DateRangePicker__modalContent">
        <div class="DateRangePicker__modalDatePickerContainer">
          <button
            :class="[
              'DateRangePicker__dateButton',
              showStartDatePicker && 'DateRangePicker__dateButton--open',
            ]"
            @click="toggleStartDatePicker()"
          >
            <span class="DateRangePicker__dateButtonLabel">{{ $t('from') }}</span>
            <Time :datetime="inputStart.toISOString()" format="MMM DD, YYYY" />
          </button>
          <Collapse>
            <DatePicker
              v-if="showStartDatePicker"
              :attributes="startAttributes"
              v-model="inputStart"
              class="DateRangePicker__datePicker"
              @input="resetOptions(), hideDatePickers()"
            />
          </Collapse>
        </div>
        <div class="DateRangePicker__modalDatePickerContainer">
          <button
            :class="[
              'DateRangePicker__dateButton',
              showEndDatePicker && 'DateRangePicker__dateButton--open',
            ]"
            @click="toggleEndDatePicker()"
          >
            <span class="DateRangePicker__dateButtonLabel">{{ $t('to') }}</span>
            <Time :datetime="inputEnd.toISOString()" format="MMM DD, YYYY" />
          </button>
          <Collapse>
            <DatePicker
              v-if="showEndDatePicker"
              :attributes="endAttributes"
              v-model="inputEnd"
              class="DateRangePicker__datePicker"
              @input="resetOptions(), hideDatePickers()"
            />
          </Collapse>
        </div>
        <DateRangeOptions
          ref="options"
          :alignment="Alignments.LEFT"
          class="DateRangePicker__modalOptions"
          @change="range => (setRange(range), hideDatePickers())"
        />
      </div>
    </ModalDialog>
  </div>
</template>

<script>
import Collapse from '@transitions/Collapse';
import Popover from '@components/Popover';
import { Button, colors, sizes as ButtonSizes } from '@components/Buttons';
import Time from '@components/Time';
import { ModalDialog, Sizes as ModalSizes } from '@components/Modal';
import DatePicker from './components/DatePicker';
import DateRangeOptions, { Alignments } from './components/DateRangeOptions';
import DateRangeButton from './components/DateRangeButton';
import dayjs from 'dayjs';

export default {
  name: 'DateRangePicker',

  components: {
    Collapse,
    Popover,
    Button,
    Time,
    ModalDialog,
    DatePicker,
    DateRangeOptions,
    DateRangeButton,
  },

  enums: {
    colors,
    ButtonSizes,
    ModalSizes,
    Alignments,
  },

  props: {
    startDate: {
      type: Date,
      default() {
        return dayjs()
          .subtract(14, 'day')
          .toDate();
      },
    },
    endDate: {
      type: Date,
      default() {
        return dayjs().toDate();
      },
    },
    showAsModal: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inputStart: this.startDate,
      inputEnd: this.endDate,
      showStartDatePicker: false,
      showEndDatePicker: false,
    };
  },

  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    startAttributes() {
      return [
        { bar: true, dates: dayjs().toDate() },
        {
          highlight: {
            start: { fillMode: 'solid' },
            base: { fillMode: 'light' },
            end: { fillMode: 'solid', color: 'gray' },
          },
          dates: {
            start: this.inputStart,
            end: this.inputEnd,
          },
        },
      ];
    },
    endAttributes() {
      return [
        { bar: true, dates: dayjs().toDate() },
        {
          highlight: {
            start: { fillMode: 'solid', color: 'gray' },
            base: { fillMode: 'light' },
            end: { fillMode: 'solid' },
          },
          dates: {
            start: this.inputStart,
            end: this.inputEnd,
          },
        },
      ];
    },
  },

  watch: {
    inputStart(to) {
      if (to.getTime() > this.inputEnd.getTime()) this.inputEnd = to;
    },
    inputEnd(to) {
      if (to.getTime() < this.inputStart.getTime()) this.inputStart = to;
    },
  },

  methods: {
    setRange(range) {
      this.inputStart = range.start;
      this.inputEnd = range.end;
    },
    resetOptions() {
      this.$refs.options.reset();
    },
    changeDate() {
      this.$emit('change', { start: this.inputStart, end: this.inputEnd });
    },
    resetDates() {
      this.inputStart = this.startDate;
      this.inputEnd = this.endDate;
    },
    openModal() {
      this.$refs.modal.open();
    },
    closeModal() {
      this.$refs.modal.close();
    },
    toggleStartDatePicker() {
      this.showStartDatePicker = !this.showStartDatePicker;
      this.showEndDatePicker = false;
    },
    toggleEndDatePicker() {
      this.showEndDatePicker = !this.showEndDatePicker;
      this.showStartDatePicker = false;
    },
    hideDatePickers() {
      setTimeout(() => {
        this.showStartDatePicker = false;
        this.showEndDatePicker = false;
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.DateRangePicker {
  .DateRangePicker__popoverContent {
    display: flex;
    gap: 20px;
    padding: 10px 0;
  }

  .DateRangePicker__datePickerContainer {
    display: flex;
    gap: 10px;
  }

  .DateRangePicker__controls {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    border-left: 1px solid $color-prim-grey;
  }

  .DateRangePicker__optionsContainer {
    margin: auto 0;
  }

  .DateRangePicker__options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .DateRangePicker__footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  .DateRangePicker__modalContent {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .DateRangePicker__modalDatePickerContainer {
    display: flex;
    flex-direction: column;
  }

  .DateRangePicker__dateButton {
    width: 252px;
    padding: 0.7em 1.5em 0.7em;
    border-radius: 3px;
    border: 1px solid #efefef;
    background-color: #ffffff;
    color: #666666;
    font-size: 16px;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 6%);
    box-shadow: 0 1.2px 0 rgb(0 0 0 / 4%);
    cursor: pointer;
    user-select: none;
    &--open {
      border: 1px solid #cbd5e0;
      border-bottom: none;
      border-radius: 3px 3px 0 0;
    }
  }

  .DateRangePicker__dateButtonLabel {
    margin-right: 5px;
  }

  &--modal .DateRangePicker__datePicker {
    border-top: none;
    border-radius: 0 0 $border-radius $border-radius;
    &::before {
      content: '';
      position: absolute;
      left: 20px;
      right: 20px;
      border-top: 1px solid #ccd6e066;
    }
  }

  &:not(.DateRangePicker--modal) .DateRangePicker__datePicker {
    border: none;
  }

  .DateRangePicker__modalOptions {
    width: fit-content;
  }
}
</style>

<template>
  <Button
    :color="colors.WHITE"
    class="DateRangeButton"
    icon="far fa-calendar-week"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span class="DateRangeButton__label">
      <Time :datetime="startDate.toISOString()" format="MMM DD, YYYY" />
      <span class="DateRangeButton__dash">—</span>
      <Time :datetime="endDate.toISOString()" format="MMM DD, YYYY" />
    </span>
  </Button>
</template>

<script>
import { Button, colors, sizes } from '@components/Buttons';
import Time from '@components/Time';

export default {
  name: 'DateRangeButton',

  components: {
    Button,
    Time,
  },

  enums: {
    colors,
    sizes,
  },

  props: {
    startDate: {
      type: [Date, String],
      required: true,
    },
    endDate: {
      type: [Date, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.DateRangeButton {
  width: 100%;

  .DateRangeButton__label {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
    padding: 0 0.4em;
  }

  .DateRangeButton__dash {
    color: $color-prim-grey;
  }

  .DateRangeButton__separator {
    margin: 0;
  }
}
</style>

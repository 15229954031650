<template>
  <ul :class="['DateRangeOptions', `DateRangeOptions--${alignment}`]">
    <li v-for="option in options" :key="option.id" class="DateRangeOptions__option">
      <i v-if="!hideIcon" :class="`DateRangeOptions__icon far fa-undo`" />
      <input
        :id="option.id"
        :value="option.id"
        type="radio"
        class="DateRangeOptions__input"
        v-model="selected"
      />
      <label :for="option.id" class="DateRangeOptions__label">{{ option.label }}</label>
    </li>
  </ul>
</template>

<script>
import dayjs from 'dayjs';

export const Alignments = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
};

export default {
  name: 'DateRangeOptions',

  props: {
    alignment: {
      type: String,
      default: Alignments.CENTER,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: '',
      options: [
        {
          id: 'lastSevenDays',
          label: this.$t('date.last_n_days', { n: 7 }),
          from: dayjs()
            .subtract(7, 'day')
            .add(1, 'day')
            .toDate(),
          to: dayjs().toDate(),
        },
        {
          id: 'lastThirtyDays',
          label: this.$t('date.last_n_days', { n: 30 }),
          from: dayjs()
            .subtract(30, 'day')
            .add(1, 'day')
            .toDate(),
          to: dayjs().toDate(),
        },
        {
          id: 'lastSixMonths',
          label: this.$t('date.last_n_month--short', { n: 6 }),
          from: dayjs()
            .subtract(6, 'month')
            .add(1, 'day')
            .toDate(),
          to: dayjs().toDate(),
        },
        {
          id: 'lastTwelveMonths',
          label: this.$t('date.last_n_month--short', { n: 12 }),
          from: dayjs()
            .subtract(1, 'year')
            .add(1, 'day')
            .toDate(),
          to: dayjs().toDate(),
        },
      ],
    };
  },

  watch: {
    selected(to) {
      if (!to) return;
      const option = this.options.find(_options => _options.id === to);
      this.$emit('change', { start: option.from, end: option.to });
    },
  },

  methods: {
    reset() {
      this.selected = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.DateRangeOptions {
  display: flex;
  flex-direction: column;
  margin: 0;

  .DateRangeOptions__icon {
    color: $color-prim-grey-dark;
  }

  .DateRangeOptions__option {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  .DateRangeOptions__input {
    display: none;
  }

  .DateRangeOptions__label {
    width: 100%;
    padding: 5px 0;
    color: $color-prim-grey-dark;
    font-weight: normal;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
      color: $color-prim-black;
    }
  }

  &--center .DateRangeOptions__label {
    text-align: center;
  }

  &--left .DateRangeOptions__label {
    text-align: left;
  }

  &--right .DateRangeOptions__label {
    text-align: right;
  }

  .DateRangeOptions__input:checked + .DateRangeOptions__label {
    color: #000000;
    text-shadow: 1px 0 0 #000000;
  }
}
</style>
